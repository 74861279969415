import { graphql } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'
import { useReveal } from 'hooks'
import { randomItem } from 'utils'
import { HoverEffect } from 'library'
import { useRef, useEffect } from 'react'
import { Aurora, GetInTouch } from 'components'
import { H1, H2, H3, Label, Content, LinkButton } from 'common'
import { React, Link, Image, styled, keyframes, mq } from 'x'

import displacementA from 'static/img/displacement-a.jpg'
import displacementB from 'static/img/displacement-b.png'
import displacementC from 'static/img/displacement-c.png'
import displacementD from 'static/img/displacement-d.jpg'
import LearningPeopleLogoURL from '../../content/images/clients/learningpeople.png'
import HealthELogoURL from '../../content/images/clients/healthefoundation.png'
import ZodiacLogoURL from '../../content/images/clients/zodiac.png'
import ParavisieLogoURL from '../../content/images/clients/paravisie.png'
import KeplarLogoURL from '../../content/images/clients/keplar.png'
import WebbersLogoURL from '../../content/images/clients/webbers.png'
import WeDigitalLogoURL from '../../content/images/clients/wedigital.png'
import YamasaLogoURL from '../../content/images/clients/yamasa.png'
import BankshopperLogoURL from '../../content/images/clients/bankshopper.png'
import HappyItalyLogoURL from '../../content/images/clients/happyitaly.png'
import DailyOddsLogoURL from '../../content/images/clients/dailyodds.png'

const effects = [
  {
    intensity: -.7,
    speedIn: 1200,
    speedOut: 1200,
    displacementImage: displacementA,
  },
  {
    intensity1: 0.1,
    intensity2: 0.1,
    angle2: Math.PI / 2,
    displacementImage: displacementB,
  },
  {
    intensity: .2,
    speedIn: 1600,
    speedOut: 1600,
    displacementImage: displacementC,
  },
  {
    intensity: -.4,
    speedIn: 700,
    speedOut: 300,
    displacementImage: displacementD,
  },
]

const createHoverEffect = (imageA, imageB) => ref => {
  if (!ref) {
    return
  }

  const effect = randomItem(effects)

  return new HoverEffect({
    parent: ref,
    image1: imageA,
    image2: imageB,
    ...effect,
  })
}

const taglineAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const TaglineWrapper = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 0 20px;
`

const AnimatedTaglineLabel = styled(props => <Label {...props} />)`
  animation: ${ taglineAnimation } 1s ease-out 1 forwards;
`

const AnimatedTaglineH1 = styled(props => <H1 {...props} />)`
  animation: ${ taglineAnimation } 1s ease-out 1 forwards;
`

const AnimatedTaglineSubtitle = styled(H3)`
  animation: ${ taglineAnimation } 1s ease-out 1 forwards;
`

const ContentWrapper = styled.div`
  margin: 105vh auto 0;
  position: relative;
  z-index: 4;
`

const ServiceWrapper = styled.ul`
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0 0 200px;
  list-style: none;

  transition: transform 2s ease-out, opacity 2s ease-out;

  ${ props => props.isRevealed
  ? 'transform: translateY(0px); opacity: 1.0;'
  : 'transform: translateY(50px); opacity: 0.0;'
};

  ${ mq.below('md') } {
    flex-flow: column;
    padding-bottom: 75px;
  }
`

const Centered = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`

const ServiceContent = styled.div`
  margin-left: 20px;
  color: #FFF;
`

const ServiceNumber = styled.span`
  font-family: "font-VgXiPuvw", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #FFF;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 2px solid #FFF;
`

const ServiceTitle = styled.h2`
  margin-top: 0;
  letter-spacing: 1px;
  font-size: 19px;
  line-height: 24px;
`

const ServiceText = styled.p`
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.85);
`

const Service = styled.li`
  display: flex;
  margin-left: ${ props => props.isFirst ? '0' : '40px' };
  margin-right: ${ props => props.isLast ? '0' : '40px' };
  align-items: flex-start;

  transition: transform 2s ease-out, opacity 2s ease-out;
  opacity: 0.0;
  transform: translateY(100px);

  ${ props => props.isRevealed
  && 'transform: translateY(0px); opacity: 1.0;'
}

  ${ mq.below('md') } {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
`

const Cases = styled.div`
  padding-bottom: 150px;

  ${ mq.below('md') } {
    padding-bottom: 50px;
  }
`

const Clients = styled.div`
  padding-bottom: 150px;

  ${ mq.below('md') } {
    padding-bottom: 50px;
  }
`

const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const Client = styled.div`
  width: 200px;
  height: 200px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ mq.below('md') } {
    width: 150px;
    height: 150px;
    padding: 20px;
  }
`

const ClientLogo = styled.img`
  width: 120px;
  object-fit: contain;
  ${ mq.below('sm') } {
    width: 110px;
  }
`

const LearningPeopleLogo = styled(ClientLogo)`
  padding: 0px 14px;
`

const HealthELogo = styled(ClientLogo)`
  padding: 0px 10px;
`

const ZodiacLogo = styled(ClientLogo)`
  padding: 0px 22px;
`

const KeplarLogo = styled(ClientLogo)`
  padding: 0px 25px;
`

const WebbersLogo = styled(ClientLogo)`
  padding: 0px 30px;
`

const WeDigitalLogo = styled(ClientLogo)`
  padding: 0px 20px;
`

const Articles = styled.div``

const ArticleText = styled.div`
  position: relative;
  display: block;
  flex-direction: column;
  text-align: ${ props => props.alignment };
  overflow: hidden;
  
  padding-left: ${ props => props.index % 2 === 0 ? '60px' : 0 };
  padding-right: ${ props => props.index % 2 !== 0 ? '60px' : 0 };
  
  ${ mq.below('xl') } {
    padding: 0;
  }
  
  ${ mq.below('sm') } {
    text-align: left;
  }
`

const ArticleTitle = styled.div`
  font-size: 35px;
  color: #FFF;
  text-decoration: none;
  position: relative;
  display: block;
  text-shadow: 0 1px 5px rgba(0,0,0,.3);
  font-family: "font-VgXiPuvw", Helvetica, Arial, sans-serif;
  
  transition: transform .5s ease-out, opacity .5s ease-out;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };
  
  transform: ${ props => props.isRevealed
  ? 'translateX(0)'
  : props => props.index % 2 === 0
    ? 'translateX(50px)'
    : 'translateX(-50px)' };

  a {
    color: #FFF;
    text-decoration: none;
  }
`

const ArticleSummary = styled.div`
  font-size: 16px;
  color: #AEAEAE;
  line-height: 27px;
  padding: 20px ${ props => props.index % 2 === 0 ? '40px' : 0 } 20px ${ props => props.index % 2 !== 0 ? '40px' : 0 };
  text-align: ${ props => props.alignment };
  max-width: 800px;

  strong {
    font-weight: 700;
    color: white;
  }
  
  transition: transform 1s ease-out, opacity 1s ease-out;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };

  transform: ${ props => props.isRevealed
  ? 'translateX(0)'
  : props => props.index % 2 === 0
    ? 'translateX(50px)'
    : 'translateX(-50px)' };

  ${ mq.below('xl') } {
    padding-left: 0;
    padding-right: 0;  
  }
  
  ${ mq.below('sm') } {
    text-align: left;
  }
`

const ArticlePunchline = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: ${ props => props.alignment };
  max-width: 800px;
  font-weight: 700;
  color: white;
  
  transition: transform .5s ease-out, opacity .5s ease-out;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };

  transform: ${ props => props.isRevealed
  ? 'translateX(0)'
  : props => props.index % 2 === 0
    ? 'translateX(50px)'
    : 'translateX(-50px)' };
  
  padding-left: ${ props => props.index % 2 !== 0 ? '40px' : 0 };
  padding-right: ${ props => props.index % 2 === 0 ? '40px' : 0 };

  ${ mq.below('xl') } {
    padding-left: 0;
    padding-right: 0;  
  }

  ${ mq.below('sm') } {
    text-align: left;
  }
`

const ArticleBackground = styled.div`
  width: 360px;
  height: 580px;
  transition: transform 2s ease-out, opacity 2s ease-out;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };
  transform: ${ props => props.isRevealed ? 'translateY(0px)' : 'translateY(100px)' };
`

const ArticleLogo = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 3;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };
  transition: transform .5s ease-out, opacity .5s ease-out;
`

const ArticleImage = styled.div`
  width: 360px;
  height: 480px;
  position: relative;

  :hover {
    ${ ArticleLogo } {
      opacity: 0;
    }
  }

  ${ mq.below('xl') } {
    margin: 20px 0px;
  }

  ${ mq.below('sm') } {
    width: 100%;
    max-width: 360px;
    height: auto;
    margin-bottom: 20px;
  }  
`

const ParallaxWrapper = styled.div`
  width: 360px;
  height: 480px;
  flex-shrink: 0;
  overflow: hidden;
  z-index: 2;

  ${ mq.below('sm') } {
    width: 100%;
    max-width: 360px;
    height: 0;
    padding-bottom: 140%;
    margin-bottom: 20px;
  }
`

const ArticleButton = styled.div`
  transition: transform 1s ease-out, opacity 1s ease-out, border-color .25s;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };

  transform: ${ props => props.isRevealed
  ? 'translateX(0)'
  : props => props.index % 2 === 0
    ? 'translateX(50px)'
    : 'translateX(-50px)' };
`

const ArticleWrapper = styled.div`
  padding: 20px 0;

  ${ mq.below('sm') } {
    overflow: hidden;
  }
`

const ArticleLabel = styled(props => <Label {...props} />)`
  margin-bottom: 30px;
  transition: transform 1s ease-out, opacity 1s ease-out;
  opacity: ${ props => props.isRevealed ? 1.0 : 0.0 };
  
  transform: ${ props => props.isRevealed
  ? 'translateX(0)'
  : props => props.index % 2 === 0
    ? 'translateX(100px)'
    : 'translateX(-100px)' };
`

const Article = styled.div`
  display: flex;
  flex-direction: ${ props => props.index % 2 === 0 ? 'row' : 'row-reverse' };
  align-items: center;
  padding: ${ props => props.index % 2 === 0 ? '20px' : '0px' };
  margin-bottom: 40px;
  transition: transform 2s ease-out, opacity 2s ease-out;
  
  ${ mq.below('xl') } {
    flex-flow: column;
    padding: 0;
  }
`

const selectImage = srcSet => {
  const select = window && window.devicePixelRatio > 1 ? '2x' : '1x'
  const srcs = srcSet.split(',')
  const filtered = srcs.filter(src => src.indexOf(select) !== -1)
  const r = new RegExp(`(\n| ${ select })`, 'g')
  return filtered[0].replace(r, '')
}

const ArticleComponent = ({ node, index }) => {
  const ref = useRef(null)
  const alignment = index % 2 === 0 ? 'left' : 'right'

  const { reveal, isRevealed } = useReveal()

  const { reveal: revealLogo, isRevealed: isLogoRevealed } = useReveal({ delay: 1370 })
  const { reveal: revealTitle, isRevealed: isTitleRevealed } = useReveal({ delay: 250 })
  const { reveal: revealSummary, isRevealed: isSummaryRevealed } = useReveal({ delay: 500 })
  const { reveal: revealPunchline, isRevealed: isPunchlineRevealed } = useReveal({ delay: 750 })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const imageA = selectImage(node.data.imageA.childImageSharp.fixed.srcSet)
    const imageB = selectImage(node.data.imageB.childImageSharp.fixed.srcSet)
    const hoverEffect = createHoverEffect(imageA, imageB)
    const context = hoverEffect(ref.current)

    return () => context.destroy()
  }, [])

  return (
    <ArticleWrapper index={ index }>
      <Content>
        <Article
          key={ node.id }
          index={ index }
          ref={ reveal }
          isRevealed={ isRevealed }
          isLogoRevealed={ isLogoRevealed }
          isTitleRevealed={ isTitleRevealed }
          isSummaryRevealed={ isSummaryRevealed }
          isPunchlineRevealed={ isPunchlineRevealed }
        >
          <ArticleImage>
            <Parallax
              disabled={ true }
              y={ [0, -10] }
              styleOuter={ {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3,
                pointerEvents: 'none',
              } }
              styleInner={ {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              } }
            >
              <ArticleLogo ref={ revealLogo } isRevealed={ isLogoRevealed }>
                <Image fixed={ node.data.logo.childImageSharp.fixed }/>
              </ArticleLogo>
            </Parallax>
            <ParallaxWrapper>
              <Parallax
                y={ ['0px', '-100px'] }
              >
                <ArticleBackground ref={ ref } isRevealed={ isRevealed }/>
              </Parallax>
            </ParallaxWrapper>
          </ArticleImage>
          <ArticleText alignment={ alignment } index={ index }>
            <ArticleLabel
              textColor={ '#737373' }
              lineColor={ '#373737' }
              alignment={ alignment }
              isRevealed={ isRevealed }
              index={ index }
            >
              { node.data.name }
            </ArticleLabel>
            <ArticleTitle ref={ revealTitle } isRevealed={ isTitleRevealed } index={ index }>
              {
                node.data.path
                  ? <Link to={ node.data.path }>{ node.data.title }</Link>
                  : node.data.title
              }
            </ArticleTitle>
            <ArticleSummary
              alignment={ alignment }
              ref={ revealSummary }
              isRevealed={ isSummaryRevealed }
              index={ index }
              dangerouslySetInnerHTML={ { __html: node.data.summary } }
            />
            {
              node.data.path
                ? (
                  <ArticleButton
                    isRevealed={ isPunchlineRevealed }
                    index={ index }
                    ref={ revealPunchline }
                  >
                    <LinkButton to={ node.data.path }>
                      View case
                    </LinkButton>
                  </ArticleButton>
                ) : (
                  <ArticlePunchline
                    ref={ revealPunchline }
                    alignment={ alignment }
                    isRevealed={ isPunchlineRevealed }
                    index={ index }
                  >
                    { node.data.punchline }
                  </ArticlePunchline>
                )
            }
          </ArticleText>
        </Article>
      </Content>
    </ArticleWrapper>
  )
}

export default props => {
  const { reveal, isRevealed } = useReveal()

  const { reveal: revealAlpha, isRevealed: isAlphaRevealed } = useReveal({ delay: 0 })
  const { reveal: revealBeta, isRevealed: isBetaRevealed } = useReveal({ delay: 370 })
  const { reveal: revealGamma, isRevealed: isGammaRevealed } = useReveal({ delay: 700 })

  return (
    <>
      <Aurora>
        <TaglineWrapper>
          <AnimatedTaglineLabel>Intro</AnimatedTaglineLabel>
          <AnimatedTaglineH1>We turn great ideas into <br/> rock-solid digital products</AnimatedTaglineH1>
          <AnimatedTaglineSubtitle as="h2">website & app development</AnimatedTaglineSubtitle>
        </TaglineWrapper>
        {/* <IDE /> */ }
      </Aurora>
      <ContentWrapper>
        <Content>
          <Centered>
            <Label alignment={ 'center' }>Services</Label>
            <H2>Pragmatic solutions using the latest tech</H2>
          </Centered>
          <ServiceWrapper
            ref={ reveal }
            isRevealed={ isRevealed }
          >
            <Service
              isFirst={ true }
              ref={ revealAlpha }
              isRevealed={ isAlphaRevealed }
            >
              <ServiceNumber>01</ServiceNumber>
              <ServiceContent>
                <ServiceTitle>Websites</ServiceTitle>
                <ServiceText>You want an impressive website that makes your competitors look bland and outdated.
                  But despite the visual spectacle the site must also be lightning quick in order to get on top of the
                  Google results.
                  We found a combination of cutting edge techniques that allows you to have your cake and eat it
                  too.</ServiceText>
              </ServiceContent>
            </Service>
            <Service
              ref={ revealBeta }
              isRevealed={ isBetaRevealed }
            >
              <ServiceNumber>02</ServiceNumber>
              <ServiceContent>
                <ServiceTitle>Apps</ServiceTitle>
                <ServiceText>A useful smartphone app with notifications is a great way to keep people coming back to
                  your brand. Thanks to the power of Flutter we are now able to create premium native
                  iOS and Android apps from a single codebase. The cost efficiency of this method is unbeatable.
                </ServiceText>
              </ServiceContent>
            </Service>
            <Service
              isLast={ true }
              ref={ revealGamma }
              isRevealed={ isGammaRevealed }
            >
              <ServiceNumber>03</ServiceNumber>
              <ServiceContent>
                <ServiceTitle>Microservices and APIs</ServiceTitle>
                <ServiceText>Need a reliable backend for your app or service? Our server stack consists of
                  high-performance and thoroughly battle tested tech like PostgreSQL and Golang.
                  We also put a lot of care into choosing the right hosting and deployment setup for the project in
                  terms of stability, scalability and performance.
                </ServiceText>
              </ServiceContent>
            </Service>
          </ServiceWrapper>
          <Clients>
            <Centered>
              <Label alignment={ 'center' }>Clients</Label>
            </Centered>
            <ClientsWrapper>
              <Client><LearningPeopleLogo src={ LearningPeopleLogoURL }></LearningPeopleLogo></Client>
              <Client><HealthELogo src={ HealthELogoURL }></HealthELogo></Client>
              <Client><ZodiacLogo src={ ZodiacLogoURL }></ZodiacLogo></Client>
              <Client><ClientLogo src={ ParavisieLogoURL }></ClientLogo></Client>
              <Client><WeDigitalLogo src={ WeDigitalLogoURL }></WeDigitalLogo></Client>
              <Client><WebbersLogo src={ WebbersLogoURL }></WebbersLogo></Client>
              <Client><KeplarLogo src={ KeplarLogoURL }></KeplarLogo></Client>
              <Client><ClientLogo src={ HappyItalyLogoURL }></ClientLogo></Client>
              <Client><ClientLogo src={ BankshopperLogoURL }></ClientLogo></Client>            
              <Client><ClientLogo src={ YamasaLogoURL }></ClientLogo></Client>
              <Client><ClientLogo src={ DailyOddsLogoURL }></ClientLogo></Client>           
            </ClientsWrapper>
          </Clients>

          {/* <Cases>
            <Centered>
              <Label alignment={ 'center' }>Cases</Label>
            </Centered>
            {
              props.data.cases.articles.map(({ node }, index) => (
                <ArticleComponent node={ node } index={ index } key={ index }/>
              ))
            }
          </Cases> */}
          <Articles>
            <Centered>
              <Label alignment={ 'center' }>Tech Stack</Label>
            </Centered>
            {
              props.data.stack.articles.map(({ node }, index) => (
                <ArticleComponent node={ node } index={ index } key={ index }/>
              ))
            }
          </Articles>
        </Content>
        <GetInTouch/>
      </ContentWrapper>
    </>
  )
}

export const pageQuery = graphql`
  query ArticlesQuery {
    cases: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "en" },
          template: { eq: "case" }
        }
      }
    ) {
      articles: edges {
        node {
          id
          data: frontmatter {
            name
            title
            path
            summary
            template
            logo {
              childImageSharp {
                fixed(width: 97) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            imageA {
              childImageSharp {
                fixed(width: 360, height: 580, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            imageB {
              childImageSharp {
                fixed(width: 360, height: 580, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    stack: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "en" },
          template: { eq: "stack" }
        }
      },
      sort: {
        fields: [frontmatter___priority],
        order: DESC
      }
    ) {
      articles: edges {
        node {
          id
          data: frontmatter {
            name
            title
            path
            summary
            template
            priority
            punchline
            logo {
              childImageSharp {
                fixed(width: 97) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            imageA {
              childImageSharp {
                fixed(width: 360, height: 580, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            imageB {
              childImageSharp {
                fixed(width: 360, height: 580, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
